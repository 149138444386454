<template>
  <div class="d-flex flex-column pt-3 pr-0 pl-0" style="flex: 1">
    <div>
      <v-text-field
        id="add-delegate-staff-list--search-field"
        v-model="searchString"
        aria-label="filter delegates list"
        class="pl-6 pr-6"
        hide-details
        label=""
        outlined
        placeholder="Search staff"
        prepend-inner-icon="search"
      />
    </div>
    <v-row
      v-if="!filteredStaff.length"
      class="d-flex align-center justify-center"
      style="min-height: 200px"
    >
      <div>
        <p class="text-center">
          We can't find any <b>staff members</b> matching your search.
        </p>
        <p class="text-center">
          Only people already in your <b>staff list</b> can be added as
          <b>delegates</b>.
        </p>
      </div>
    </v-row>
    <v-list
      v-if="filteredStaff.length"
      style="background-color: inherit"
      two-line
    >
      <div v-for="s in filteredStaff" :key="s.detuserid">
        <v-list-item class="pl-6 pr-6" @click="goToStaffAppDetails(s)">
          <InitialsIcon
            :given-name="s.firstname"
            :surname="s.lastname"
            class="mr-3 initialsIcon"
            size="42"
          />
          <v-list-item-content>
            <v-container>
              <div
                :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                class="d-flex align-md-center justify-md-space-between"
              >
                <div>
                  <v-list-item-title
                    >{{ s.firstname + " " + s.lastname }}
                  </v-list-item-title>
                </div>
                <div>
                  <v-list-item-subtitle
                    >{{ s.staffRoleFormatted }}
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-container>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              :aria-label="'select ' + s.firstname + '-' + s.lastname"
              icon
              ripple
            >
              <v-icon>
                mdi-plus-circle-outline
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { InitialsIcon } from "@nswdoe/doe-ui-core";
import { mapGetters } from "vuex";
import { searchFilterContains } from "@/utils/filters";

export default {
  name: "AddDelegateStaffList",
  components: {
    InitialsIcon
  },
  data() {
    return {
      searchString: ""
    };
  },
  computed: {
    ...mapGetters({
      staff: "staffByLocation",
      delegates: "delegatesByLocation",
      locationId: "selectedSchoolId"
    }),
    delegatedStaffIds: function() {
      return this.delegates(this.locationId).map(s => s.detuserid);
    },
    filteredStaff: function() {
      const excludedRoles = [
        "school.principal",
        "contractor",
        "casual"
      ];
      // filter out already delegated staff, principals, contractor and casual
      const filteredStaff = this.staff(this.locationId).filter(
        s =>
          !this.delegatedStaffIds.some(id => s.detuserid === id) &&
          !excludedRoles.includes((s.staffRole || "").toLowerCase())
      );

      if (this.searchString !== null && this.searchString !== "") {
        return filteredStaff.filter(s =>
          searchFilterContains(
            [s.firstname, s.lastname, s.staffRoleFormatted],
            this.searchString
          )
        );
      }
      return filteredStaff;
    }
  },
  methods: {
    goToStaffAppDetails(staff) {
      const path = "delegates/" + staff.detuserid;
      this.$router.push({ path: path });
    }
  }
};
</script>

<style scoped>
div.v-list > div > .v-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0);
}
</style>
